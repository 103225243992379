<template>
    <div class="container">
        <modal-add-edit-absence modalId="modalEditAbsence" ref="modalEditAbsence" title="Izmena odsustva" mode="EDIT" :absence.sync="editedAbsence" @confirmed="updateAbsence"></modal-add-edit-absence>
        <modal-confirm @confirm="deleteSelectedAbsence" inputId="confirmModal" title="Potvrda brisanja odsustva" message="Da li ste sigurni da želite da obrišete odustvo?"/>
        <div>
            <ejs-grid
                ref="absenceRequests"
                :dataSource="absences" 
                class="absence-requests-grid"
                :allowFiltering='true'
                :allowSorting='true'
                :showColumnMenu='true'
                :filterSettings='filterSettings'
                :allowPaging='false' 
                :pageSettings='pageSettings'
                :recordDoubleClick="absenceDBClick"
                :toolbarClick='clickHandler'
                :toolbar='toolbar'
                :allowResizing='true'
                :allowTextWrap='true'
                >
                <e-columns>

                    <e-column field='id' headerText='ID' width='120' textAlign='Right' :isPrimaryKey='true' :visible='false' ></e-column>
                    <e-column field='user.username' headerText='Ime i Prezime' width='120' textAlign='Left' :template="usernameTemplate"></e-column>
                    
                    <!-- <e-column field='employee.first_name' headerText='Ime' width='120' textAlign='Left'></e-column>
                    <e-column field='employee.last_name' headerText='Prezime' width='120'></e-column> -->

                    <e-column field='start_date' :valueAccessor='dateAccessor' headerText='Početak' width='130' editType='datepickeredit' textAlign='Right' :visible='true'></e-column>
                    <e-column field='end_date' :valueAccessor='dateAccessor' headerText='Kraj' width='130' editType='datepickeredit' format="yMd" textAlign='Right' :visible='true'></e-column>
                    <e-column field='absence_type' :valueAccessor='absenceTypeAccessor' headerText='Razlog' width='120' editType='dropdownedit'></e-column>
                    <e-column field='absence_status' :valueAccessor='absenceStatusAccessor' headerText='Status' width='120' editType='dropdownedit'></e-column>
                    <e-column field='comment' headerText='Komentar' width='150'></e-column>


                    <e-column field='created_at' :valueAccessor='dateTimeAccessor' headerText='Kreirano' width='200' format="yMd HH:mm" textAlign='Right' :visible='true'></e-column>
                    <e-column field='updated_at' :valueAccessor='dateTimeAccessor' headerText='Izmenjeno' width='200' format="yMd HH:mm" textAlign='Right' :visible='false'></e-column>
                    <e-column field='created_by.username' headerText='Napravio zahtev' width='130' :visible='false'></e-column>


                </e-columns>
            </ejs-grid> 
        </div>
    </div>
</template>

<script>

    import AbsencesService from '@/service/AbsencesService';
    import { Sort, Page, ColumnMenu, Filter, Group, Toolbar, Edit } from "@syncfusion/ej2-vue-grids";
    import UtilService from '@/service/UtilService.js';
    import { Modal } from 'bootstrap';
    import EmployeesService from '@/service/EmployeesService';

    import ModalConfirm from '@/components/modals/ModalConfirm.vue';
    import ModalAddEditAbsence from '@/components/modals/ModalAddEditAbsence';
    import UsernameTemplate from '@/components/grid-templates/UsernameTemplate.vue';


    export default {
        components: { ModalAddEditAbsence, ModalConfirm },


        data() {
            return {
                absences: null,

                filterSettings: { type: "Excel" },
                pageSettings: { pageCount: 20},
                editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true },

                toolbar: [{ text: 'Izmeni', tooltipText: 'Izmeni', prefixIcon: 'e-edit', id: 'editAbsence'}, { text: 'Obriši', tooltipText: 'Obriši', prefixIcon: 'e-delete', id: 'deleteAbsence'}],

                modalEditAbsebce: null,
                confirmModal: null,

                editedAbsence: null,
                

                usernameTemplate: function() {
                    return {
                        template: {
                            extends: UsernameTemplate,
                            propsData: {
                                showAvatar: true,
                                showName: true,
                                showLastName: true,
                                showUsername: false,
                                profilePage: 'EmployeeAbsences',
                                dataLocation: 'employee',
                                
                            },
                        }

                    };
                },
            }
        },

        provide: {
            grid: [Sort, Page, ColumnMenu, Filter, Group, Toolbar, Edit ]
        },


        methods: {
            
            formatDate: UtilService.formatDate,
            formatDateTime: UtilService.formatDateTime,

            dateTimeAccessor(field, data) {
                return data[field] ? this.formatDateTime(data[field]) : '/';
            },
            dateAccessor(field, data) {
                return data[field] ? this.formatDate(data[field]) : '/';
            },
            absenceTypeAccessor(field, data) {
                return data[field] ? UtilService.translateAbsenceType(data[field]) : '/';
            },
            absenceStatusAccessor(field, data) {
                let approved = data.approved_by?.username;


                let status = data[field] ? UtilService.translateAbsenceStatus(data[field]) : '/';
                if (approved)
                    return status + " ( " + approved + " )"
                else return status;
            },


            async loadRequests() {
                let result = await AbsencesService.getAbsencesRequests();

                this.absences = result.data;
                this.refresh()

            },

            absenceDBClick(event) {
                this.editedAbsence = event.rowData;
                this.showEditAbsenceModal();
            },

            
            refresh() {
                this.$refs.absenceRequests.ej2Instances.refresh();
            },

            clickHandler(args) {

                if (args.item.id === "editAbsence"){
                    let selected = (this.$refs.absenceRequests.ej2Instances.getSelectedRecords());
                    if (selected.length > 0) selected = selected[0];
                    else return;

                    this.editedAbsence = selected;

                    this.showEditAbsenceModal();
                }

                if (args.item.id === "deleteAbsence"){
                    let selected = (this.$refs.absenceRequests.ej2Instances.getSelectedRecords());
                    if (selected.length > 0) selected = selected[0];
                    else return;
                    
                    this.confirmModal.show();
                }
            },

            
            async updateAbsence() {
                try {
                    this.editedAbsence.employee_id = this.editedAbsence.employee.user.id;
                    let result = await EmployeesService.postAbsence(this.editedAbsence)
                    this.editedAbsence = result.data;
                    this.modalEditAbsebce.hide();
                    
                    this.loadRequests();
                    this.toast('Uspešno izmenjeno odsustvo.');
                } catch (error) {
                    console.log(error)
                    alert("Neuspesno")
                }
            },

            showEditAbsenceModal() {
                this.$refs.modalEditAbsence.load();
                this.modalEditAbsebce.show();
            },
            

            deleteSelectedAbsence(){
                    let selected = (this.$refs.absenceRequests.ej2Instances.getSelectedRecords());
                    if (selected.length > 0) selected = selected[0];
                    else return;

                    this.deleteAbsence(selected)
            },

            toast(message, type) {
                this.$toasted.show(message, { 
                    type: type,
                    theme: "toasted-primary", 
                    position: "top-center", 
                    duration : 3000
                });
            },
        },

        

        mounted() {
            this.modalEditAbsebce = new Modal(document.getElementById('modalEditAbsence'));
            this.confirmModal = new Modal(document.getElementById('confirmModal'));
        },

        created() {
            this.loadRequests();
        }
    }
</script>

<style>

</style>