<template>
    <div class="modal fade" :id="modalId" tabindex="-1" :aria-labelledby="modalId + 'Label'" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"> {{ title }} </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <div style="text-align:center" v-if="absence">
                        <form :id="'modal-absence-form-' + modalId">
                            <div class="container col-md-10 d-grid gap-2">
                                <select v-model="absence.employee" class="form-select"
                                    aria-label="Default select example" disabled required>
                                    <option :value="absence.employee">{{ absence.employee.user.first_name }}
                                        {{ absence.employee.user.last_name }}</option>
                                </select>

                                <template v-if="vacation_progress">
                                    <h5>Slobodni dani {{ vacation_progress.free }}</h5>
                                    <div class="progress h-16" style="height: 24px;">
                                        <div class="progress-bar bg-success" role="progressbar"
                                            :style="'width: ' + vacation_progress.vacation_percent + '%'"
                                            aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">{{
                                                vacation_progress.vacation }} odmora
                                        </div>
                                        <div class="progress-bar" role="progressbar"
                                            :style="'width: ' + vacation_progress.sickness_percent + '%'"
                                            aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">{{
                                                vacation_progress.sickness }} bolovanja
                                        </div>
                                        <div class="text-center bg-gray-50" role="progressbar"
                                            :style="'width: ' + vacation_progress.free_percent + '%'" aria-valuenow="20"
                                            aria-valuemin="0" aria-valuemax="100">
                                        </div>
                                    </div>
                                </template>


                                <div class="d-flex px-2 py-4 w-100 btn-separate-group" role="group"
                                    aria-label="Basic radio toggle button group">
                                    <div class="col-4 p-1 h-100">

                                        <input type="radio" v-model="absence.absence_type" class="btn-check"
                                            name="btnradio" id="btnradio1" autocomplete="off" value="VACATION" required>
                                        <label
                                            class="btn form-control w-100 btn-outline-primary d-flex justify-content-between p-3 col-6 h-100"
                                            for="btnradio1">
                                            <span class="vertical-center">Odmor</span>
                                        </label>
                                        <div class="invalid-feedback">
                                            Metod plaćanja je obavezno polje.
                                        </div>
                                    </div>


                                    <div class="col-4 p-1">

                                        <input type="radio" v-model="absence.absence_type" class="btn-check"
                                            name="btnradio" id="btnradio2" autocomplete="off" value="OTHER" required>
                                        <label
                                            class="btn form-control w-100 btn-outline-primary d-flex justify-content-between p-3  col-6"
                                            for="btnradio2">
                                            <span class="vertical-center">Slobodan dan</span>
                                        </label>

                                    </div>

                                    <div class="col-4 p-1">

                                        <input type="radio" v-model="absence.absence_type" class="btn-check"
                                            name="btnradio" id="btnradio3" autocomplete="off" value="SICKNESS"
                                            @input="selectedSickness" required>
                                        <label
                                            class="btn form-control w-100 btn-outline-primary d-flex justify-content-between p-3 col-6 h-100"
                                            for="btnradio3">
                                            <span class="vertical-center">Bolovanje</span>
                                        </label>

                                    </div>

                                </div>

                                <template v-if="absence.absence_type">
                                    <div class="form-floating">
                                        <input v-model="absence.start_date" class="form-control" type="date"
                                            :max="absence.end_date" @change="startDateChanged"
                                            placeholder="Početni datum:" aria-label="default input example" required>
                                        <label for="floatingTextarea">Početak</label>
                                    </div>

                                    <div v-if="absence.absence_type == 'SICKNESS' && !absence.id">

                                        <h5>Dodaj doznaku</h5>
                                        <input type="file" />
                                    </div>
                                    <div v-if="absence.absence_type != 'SICKNESS' || absence.id" class="form-floating">
                                        <input v-model="absence.end_date" class="form-control" type="date"
                                            :min="absence.start_date" @change="endDateChanged"
                                            placeholder="Krajnji datum:" aria-label="default input example">
                                        <label for="floatingTextarea">Kraj</label>
                                    </div>

                                    <template v-if="totalDays">
                                        <h5 v-if="totalDays == Infinity">Na neodređeno</h5>
                                        <h5 v-else>{{ totalDays }} Dana odsustva</h5>

                                    </template>

                                    <!-- <div class="form-floating">

                                    <select v-model="absence.absence_type" class="form-select"
                                        aria-label="Default select example" required>
                                        <option disabled :value="undefined">Razlog odsustva</option>
                                        <option value="VACATION">Odmor</option>
                                        <option value="SICKNESS">Bolovanje</option>
                                        <option value="RELIGIOUS">Verski praznik</option>
                                        <option value="OTHER">Drugi razlog</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        Razlog odsustva je obavezno polje.
                                    </div>
                                    <label for="floatingTextarea">Razlog</label>
                                </div> -->

                                    <div v-if="mode == 'EDIT' && !isEmployee" class="form-floating">

                                        <select v-model="absence.absence_status" class="form-select"
                                            aria-label="Default select example" required>
                                            <option v-if="absence.absence_status == 'DEFAULT'" value="DEFAULT">
                                                <font-awesome-icon icon="check" />Aktivan
                                            </option>
                                            <option value="APPROVED"> <font-awesome-icon icon="check" />Odobri</option>
                                            <option v-if="changed" value="ADMIN_CHANGE">Predlozi izmenu</option>
                                            <option value="REJECTED">Nije odobreno</option>
                                        </select>
                                        <div class="invalid-feedback">
                                            Status odsustva je obavezno polje.
                                        </div>
                                        <label for="floatingTextarea">Status</label>
                                    </div>

                                    <div v-if="mode == 'ADD' && !isEmployee" class="form-floating">

                                        <select v-model="absence.absence_status" class="form-select"
                                            aria-label="Default select example" required>
                                            <option value="REQUESTED"> <font-awesome-icon icon="check" />Čeka odobrenje
                                            </option>
                                            <option value="APPROVED"> <font-awesome-icon icon="check" />Odobri</option>
                                        </select>
                                        <div class="invalid-feedback">
                                            Status odsustva je obavezno polje.
                                        </div>
                                        <label for="floatingTextarea">Status</label>
                                    </div>

                                    <div class="form-floating">
                                        <textarea :disabled="!isEmployee && mode == 'EDIT'"
                                            v-model="absence.request_message" style="height: 100px" class="form-control"
                                            type="text" placeholder="Zahtev:" aria-label="default input example" />
                                        <label for="floatingInput">Razlog:</label>
                                    </div>

                                    <div class="form-floating">
                                        <textarea :disabled="isEmployee" v-model="absence.comment" style="height: 100px"
                                            class="form-control" type="text" placeholder="Komentar:"
                                            aria-label="default input example" />
                                        <label for="floatingInput">Komentar:</label>
                                    </div>

                                    <ul class="list-group list-group-flush">
                                        <li v-if="absence.approved_by && absence.approved_at" class="list-group-item">
                                            Odobrio:
                                            {{ absence.approved_by.first_name }} {{ absence.approved_by.last_name }}
                                            <small>{{ formatDateTime(absence.approved_at) }}</small>
                                        </li>
                                        <li v-if="absence.created_by && absence.created_at" class="list-group-item">
                                            Napravio
                                            zahtev:
                                            {{ absence.created_by.first_name }} {{ absence.created_by.last_name }}
                                            <small>{{ formatDateTime(absence.created_at) }}</small>
                                        </li>
                                        <li v-if="absence.updated_at" class="list-group-item">Poslednja izmena:
                                            <small>{{ formatDateTime(absence.updated_at) }}</small>
                                        </li>
                                    </ul>

                                    <h3 class="mt-4" v-if="insuficientDays">Nemate dovoljno slobodnih dana</h3>

                                </template>


                            </div>
                        </form>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"> Zatvori </button>
                    <button
                        :disabled="insuficientDays || (totalDays && (totalDays < 0 || totalDays > 360) && totalDays != Infinity)"
                        @click="confirm" type="button" class="btn btn-primary"> Potvrdi </button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import UtilService from '@/service/UtilService.js';
import moment from "moment";
import EmployeesService from '@/service/EmployeesService.js';


export default {

    props: {
        modalId: String,
        title: String,
        mode: String,
        absence: Object
    },

    data() {
        return {
            changed: false,
            absenceStats: null,
        }
    },

    watch: {
        absence: {
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.getAbsenceStats();
                }
            },
            deep: true
        }
    },

    computed: {



        ...mapState([
            'user',
        ]),

        isEmployee() {
            return this.user.role != "ADMIN" && this.user.id === this.absence.employee.user.id
        },

        totalDays() {
            if (!this.absence) return undefined;
            if (!this.absence.start_date) return undefined;
            if (!this.absence.end_date) return Infinity;
            return this.daysBetween();

        },

        insuficientDays() {
            if (this.absence?.absence_type != "VACATION") return false
            if (this.absence?.end_date == undefined) return false
            if (this.vacation_progress && this.vacation_progress.free < this.totalDays) {
                return true
            }
            return false
        },

        vacation_progress() {
            if (this.absenceStats) {

                let sickness = this.absenceStats.sickness;
                let vacation = this.absenceStats.vacation + this.absenceStats.other;

                let total = this.absence.employee.vacation_days
                if (vacation > total) {
                    total = vacation
                }


                total += sickness

                let free = total - sickness - vacation

                return {
                    total: total,
                    vacation: vacation,
                    sickness: sickness,
                    free: free,

                    vacation_percent: (vacation / total) * 100,
                    sickness_percent: (sickness / total) * 100,
                    free_percent: (free / total) * 100
                }
            }
            return null
        }
    },

    methods: {
        formatDateTime: UtilService.formatDateTime,

        daysBetween() {
            let a = UtilService.convertMoment(this.absence.start_date);
            let b = UtilService.convertMoment(this.absence.end_date);
            return this.workingDaysBetween(a, b)
        },

        workingDaysBetween(a, b) {
            if (a > b) return 0

            let workDays = 0
            for (var m = moment(a); m.diff(b, 'days') <= 0; m.add(1, 'days')) {
                if (!(m.day() == 6 || m.day() == 0)) {
                    workDays += 1
                }
            }
            return workDays
        },

        async getAbsenceStats() {
            const params = {
                employee_id: this.absence.employee.id
            }
            await EmployeesService.getAbsenceStats(params)
                .then(response => {
                    this.absenceStats = response.data;
                })
                .catch((error) => { alert(error.message); });
        },

        startDateChanged() {
            this.changedRequest();
        },

        endDateChanged() {
            this.changedRequest();
        },

        selectedSickness() {
            this.absence.end_date = undefined
        },

        changedRequest() {
            if (this.mode != "EDIT") return

            this.changed = true;

            if (this.absence.absence_status == "REQUESTED")
                this.absence.absence_status = "ADMIN_CHANGE"
        },

        confirm() {
            if (this.validate()) this.$emit('confirmed');
        },

        validate() {
            let form = document.getElementById('modal-absence-form-' + this.modalId);
            if (this.validateForm(form) === false)
                return;

            return true;
        },

        load() {
            // if (this?.absence?.absence_status == "REQUESTED")
            //     this.absence.absence_status = null;

        },

        validateForm(form) {
            form.classList.add('was-validated');
            return form.checkValidity();
        },
    },



}
</script>

<style>
p {
    font-size: 140%;
}
</style>